/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'core-js';
import 'turbolinks';

import '../app-bootstrap';
import '../scss/application';
import { initAnalytics, onDomReady } from '../utils';

initAnalytics();

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext); // eslint-disable-line react-hooks/rules-of-hooks
ReactRailsUJS.detectEvents();

let _scrollPosition;
onDomReady(() => {
  window.Turbolinks.setProgressBarDelay(0);
  if (_scrollPosition) {
    window.scrollTo.apply(window, _scrollPosition);
    _scrollPosition = null;
  }
  window.Turbolinks.reload = () => {
    _scrollPosition = [window.scrollX, window.scrollY];
    window.Turbolinks.visit(window.location.href, { action: 'replace' });
  };
});
